import React from 'react';
import scen_radar_chart from 'images/scen_radar_chart.png'; 
import UsesSection from 'components/sections/UsesSection';




function Compare() {

    return (
        <UsesSection 
            title={"Strategy"}
            content="Population growth, Changing Building types, Changing Service Lines, Compare"
            link_backward="/uses/planner-designer"
            link_forward={null}
            media={<div className="uses-video">
            <video
                className="uses-video"
                canplay="this.play()"
                onloadeddata="this.play();"
                playsinline
                id="info-video"
                title="Video"
                onclick="this.play()"
                controls="controls"
                loop
            >
                <source type="video/mp4" src="https://storage.googleapis.com/media-buckets-prod-forsightdigital-com/videos/Strategy_Short.mp4"></source>
            </video>

        </div>}
    
        />
    )
}

export default Compare;